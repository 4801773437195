import ScrollAnimation from "react-animate-on-scroll";
import ClaimButton from "../ClaimButton/ClaimButton";
import styles from "./JoinCommunity.module.scss";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRightRed.svg";
import twitter from "src/modules/landing/assets/img/svg/x.svg";
import instagram from "src/modules/landing/assets/img/svg/instagram.svg";
import youtube from "src/modules/landing/assets/img/svg/youtube.svg";
import telegram from "src/modules/landing/assets/img/svg/telegram.svg";
import cl from "classnames";

export const JoinCommunity = () => {
	return (
		<div className={styles.content}>
			<div className={styles.title}>
				Join Our <span>Community</span>
			</div>
			<div className={styles.icons}>
				<div className={cl(styles.icon, styles.iconTop)}>
					<img src={twitter} alt="" />
				</div>
				<div className={cl(styles.icon, styles.iconWhite)}>
					<img src={instagram} alt="" />
				</div>
				<ScrollAnimation animateIn={styles.animateBtn} initiallyVisible={true}>
					<div className={styles.btns}>
						<ClaimButton className={styles.btn}>Join now</ClaimButton>
						<div className={styles.arrowRight}>
							<img src={arrowRight} alt="" />
						</div>
					</div>
				</ScrollAnimation>
				<div className={styles.icon}>
					<img src={youtube} alt="" />
				</div>
				<div className={cl(styles.icon, styles.iconWhite, styles.iconTop)}>
					<img src={telegram} alt="" />
				</div>
			</div>
			<div className={styles.iconsMobile}>
				<div className={styles.containerIconsMobile}>
					<div>
						<div className={styles.icon}>
							<img
								src={twitter}
								alt=""
								style={{ width: "30px", height: "27px" }}
							/>
						</div>
						<div className={styles.icon}>
							<img
								src={youtube}
								alt=""
								style={{ width: "33px", height: "27px" }}
							/>
						</div>
					</div>
					<div>
						<div className={cl(styles.icon, styles.iconWhite)}>
							<img
								src={instagram}
								alt=""
								style={{ width: "28px", height: "27px" }}
							/>
						</div>
						<div className={cl(styles.icon, styles.iconWhite)}>
							<img
								src={telegram}
								alt=""
								style={{ width: "31px", height: "27px" }}
							/>
						</div>
					</div>
				</div>
				<ScrollAnimation animateIn={styles.animateBtn} initiallyVisible={true}>
					<div className={cl(styles.btns, styles.btnsMobile)}>
						<ClaimButton className={styles.btn}>Join now</ClaimButton>
						<div className={styles.arrowRight}>
							<img src={arrowRight} alt="" />
						</div>
					</div>
				</ScrollAnimation>
			</div>
		</div>
	);
};
