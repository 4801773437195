import styles from "./AboutTheCollection.module.scss";
import backgroundRed from "src/modules/landing/assets/img/svg/redBackground.svg";
import pepe from "src/modules/landing/assets/img/png/character.png";
import ClaimButton from "../ClaimButton/ClaimButton";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";
import cl from "classnames";
import ScrollAnimation from "react-animate-on-scroll";

export const AboutTheCollection = () => {
	return (
		<div className={styles.content} id={"aboutTheCollection"}>
			<div className={styles.title}>watch tutorial!</div>
			<div className={styles.mainContent}>
				<div className={styles.right}></div>
				<div className={styles.left}></div>
				<img src={backgroundRed} alt="" className={styles.backgroundRed} />
				<div className={styles.titleBlock}>
					<div className={styles.about}>about</div>
					<div className={styles.titleRow}>
						<div className={styles.about}>the</div>
					</div>
					<div className={styles.about}>collection</div>
				</div>
				<img src={pepe} alt="" className={styles.pepe} />
				<ScrollAnimation animateIn={styles.animateBtn} initiallyVisible={true}>
					<div className={styles.btns}>
						<ClaimButton className={styles.btn}>claim free-mint</ClaimButton>
						<div className={styles.arrowRight}>
							<img src={arrowRight} alt="" />
						</div>
					</div>
				</ScrollAnimation>
				<div className={styles.cards}>
					<div className={cl(styles.card, styles.card1)}>
						<div>1000</div>
						<div>NFTs</div>
					</div>
					<div className={cl(styles.card, styles.card2)}>
						<div>exclusive</div>
						<div>limited SigmaVerse founding colection</div>
					</div>
					<div className={cl(styles.card, styles.card3)}>
						<div>25%</div>
						<div>SigmaVerse Revenue Distrebuted</div>
					</div>
				</div>
			</div>
		</div>
	);
};
