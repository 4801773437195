import styles from "./Footer.module.scss";
import character from "src/modules/landing/assets/img/png/characterFooter.png";
import shadowFooter from "src/modules/landing/assets/img/png/shadowFooter.png";
import logo from "src/modules/common/assets/img/svg/pepexTitle.svg";
import twitter from "src/modules/landing/assets/img/svg/x.svg";
import instagram from "src/modules/landing/assets/img/svg/instagram.svg";
import youtube from "src/modules/landing/assets/img/svg/youtube.svg";
import telegram from "src/modules/landing/assets/img/svg/telegram.svg";
import headerBackground from "src/modules/common/assets/img/svg/headerBackground.svg";
import cl from "classnames";
import lineFooter from "src/modules/landing/assets/img/svg/lineFooter.svg";
import lineFooterMobile from "src/modules/landing/assets/img/svg/lineFooterMobile.svg";
import { useWindowSize } from "@uidotdev/usehooks";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
	const windowSize = useWindowSize();

	return (
		<div className={styles.content}>
			<img src={shadowFooter} alt="" className={styles.shadowFooter} />
			<img src={headerBackground} alt="" className={styles.headerBackground} />
			<div className={styles.wMainContent}>
				<img src={character} alt="" className={styles.character} />
				<div className={styles.mainContent}>
					<div className={styles.top}>
						<img src={logo} alt="" className={styles.logo} />
						<div className={styles.socialIcons}>
							<div className={styles.icon}>
								<img
									src={twitter}
									alt=""
									style={{ width: "22px", height: "20px" }}
								/>
							</div>
							<div className={cl(styles.icon, styles.iconWhite)}>
								<img
									src={instagram}
									alt=""
									style={{ width: "19px", height: "19px" }}
								/>
							</div>
							<div className={styles.icon}>
								<img
									src={youtube}
									alt=""
									style={{ width: "24px", height: "19px" }}
								/>
							</div>
							<div className={cl(styles.icon, styles.iconWhite)}>
								<img
									src={telegram}
									alt=""
									style={{ width: "23px", height: "20px" }}
								/>
							</div>
						</div>
					</div>
					<div className={styles.menu}>
						<HashLink smooth to="/#howToMint" className={styles.text}>
							How to mint
						</HashLink>
						<HashLink smooth to="/#aboutTheCollection" className={styles.text}>
							about
						</HashLink>
						<HashLink smooth to="/#exploreCharacters" className={styles.text}>
							characters
						</HashLink>
						<HashLink smooth to={"/#pvp"} className={styles.text}>
							Pvp mode
						</HashLink>
						<HashLink smooth to={"/#roadmap"} className={styles.text}>
							roadmap
						</HashLink>
						<HashLink smooth to={"/#tokenomics"} className={styles.text}>
							tokenomics
						</HashLink>
					</div>
					<img
						src={Number(windowSize.width) > 628 ? lineFooter : lineFooterMobile}
						alt=""
						className={styles.line}
					/>
					<div className={styles.copyright}>
						<div>© 2024 PEPEX. All rights reserved.</div>
						<div className={cl(styles.copyright, styles.terms)}>
							<div>Privacy Policy</div>
							<div>Terms & Conditions</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
