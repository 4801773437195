import ClaimButton from "../ClaimButton/ClaimButton";
import styles from "./MainBanner.module.scss";
import arrowRightRed from "src/modules/landing/assets/img/svg/arrowRightRed.svg";
import shadowRed from "src/modules/landing/assets/img/png/shadowRed.png";
import ScrollAnimation from "react-animate-on-scroll";
import titleRed from "src/modules/landing/assets/img/svg/titleRed.svg";
import character from "src/modules/landing/assets/img/png/characterProfile.png";
import characterCard from "src/modules/landing/assets/img/png/characterCard.png";
import characterCard1 from "src/modules/landing/assets/img/png/characterCard1.png";
import characterCard2 from "src/modules/landing/assets/img/png/characterCard2.png";
import characterCard3 from "src/modules/landing/assets/img/png/characterCard3.png";

export const MainBanner = () => {
	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div className={styles.titleColumn}>
					<div className={styles.title}>
						<div>
							Sigma
							<br /> verse
						</div>
						<div className={styles.titleRed}>
							<div>NFTS</div>
							<img src={titleRed} alt="" />
						</div>
					</div>
					<div className={styles.subTitle}>
						Don’t miss our first NFT drop. Release date will come soon
					</div>
					<ScrollAnimation
						animateIn={styles.animateBtn}
						initiallyVisible={true}
					>
						<div className={styles.btns}>
							<ClaimButton className={styles.btn}>claim free-mint</ClaimButton>
							<div className={styles.arrowRight}>
								<img src={arrowRightRed} alt="" />
							</div>
						</div>
					</ScrollAnimation>
				</div>
				<div className={styles.characterBlock}>
					<img src={character} alt="" className={styles.character} />
					<div className={styles.characterColumn}>
						<div className={styles.characterTitle}>
							Become
							<br /> the true
							<br /> sigma
						</div>
						<div className={styles.dayTabs}>
							<div className={styles.dayTab}>
								<div>??</div>
								<div>days</div>
							</div>
							<div className={styles.dayTab}>
								<div>??</div>
								<div>hours</div>
							</div>
							<div className={styles.dayTab}>
								<div>??</div>
								<div>mins</div>
							</div>
							<div className={styles.dayTab}>
								<div>??</div>
								<div>secs</div>
							</div>
						</div>
						<div className={styles.nftsRemaining}>
							<div>NFTs remaining</div>
							<div>1000</div>
						</div>
					</div>
				</div>
				<div className={styles.gradientRed}></div>
			</div>
			<img src={shadowRed} alt="" className={styles.shadowRed} />
			<div className={styles.characters}>
				<img src={characterCard} alt="" className={styles.characterCard} />
				<img src={characterCard1} alt="" className={styles.characterCard1} />
				<img src={characterCard2} alt="" className={styles.characterCard2} />
				<img src={characterCard3} alt="" className={styles.characterCard3} />
			</div>
		</div>
	);
};
