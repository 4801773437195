import styles from "./DiveInto.module.scss";
import rose from "src/modules/landing/assets/img/png/rose.png";
import shadowRose from "src/modules/landing/assets/img/svg/shadowRose.svg";
import luckyTicket from "src/modules/landing/assets/img/png/luckyTicket.png";
import bux from "src/modules/landing/assets/img/png/bux.png";
import hand from "src/modules/landing/assets/img/png/hand.png";
import pepe from "src/modules/landing/assets/img/png/pepe.png";
import cube from "src/modules/landing/assets/img/png/cube.png";
import lightning from "src/modules/landing/assets/img/png/lightning.png";
import backgroundWhite from "src/modules/landing/assets/img/svg/whiteBackground.svg";
import sigmaverseTitle from "src/modules/landing/assets/img/png/sigmaverseTitle.png";
import cl from "classnames";
import ScrollAnimation from "react-animate-on-scroll";
import ClaimButton from "../ClaimButton/ClaimButton";
import lightningWhite from "src/modules/landing/assets/img/svg/lightningWhite.svg";

export const DiveInto = () => {
	return (
		<div className={styles.content}>
			<div className={styles.title}>
				<div>dive</div>
				<div>into</div>
			</div>
			<div className={styles.mainContent}>
				<img src={shadowRose} alt="" className={styles.shadowRose} />
				<img src={sigmaverseTitle} alt="" className={styles.sigmaverseTitle} />
				<div className={styles.right}></div>
				<div className={styles.left}></div>
				<img src={backgroundWhite} alt="" className={styles.backgroundWhite} />
				<div className={styles.row}>
					<div className={styles.column}>
						<div className={cl(styles.tab, styles.tab1)}>
							<img src={hand} alt="" className={styles.hand} />
							<div>
								Tap and grow your character. The more you tap, the faster you
								grow.
							</div>
						</div>
						<div className={cl(styles.tab, styles.tab2)}>
							<img src={luckyTicket} alt="" className={styles.luckyTicket} />
							<div>
								 Win lucky tickets daily. For each ticket, you get 50$ in SOL.
							</div>
						</div>
						<div className={cl(styles.tab, styles.tab3)}>
							<img src={lightning} alt="" className={styles.lightning} />
							<div>
								Use energy to tap. Wait for it to replenish or boost it
								yourself.
							</div>
						</div>
					</div>
					<img src={rose} alt="" className={styles.rose} />
					<div className={styles.column}>
						<div className={cl(styles.tab, styles.tab4)}>
							<img src={pepe} alt="" className={styles.pepe} />
							<div>
								Use NFTs as in-game skins to get
								<br /> special bonuses
							</div>
						</div>
						<div className={cl(styles.tab, styles.tab5)}>
							<img src={bux} alt="" className={styles.bux} />
							<div>Invite friends and earn daily $$$ together. </div>
						</div>
						<div className={cl(styles.tab, styles.tab6)}>
							<img src={cube} alt="" className={styles.cube} />
							<div>Unlock the true sigma male in you.</div>
						</div>
					</div>
				</div>
				<ScrollAnimation animateIn={styles.animateBtn} initiallyVisible={true}>
					<div className={styles.btns}>
						<ClaimButton className={styles.btn}>play now</ClaimButton>
						<div className={styles.arrowRight}>
							<img src={lightningWhite} alt="" />
						</div>
					</div>
				</ScrollAnimation>
			</div>
		</div>
	);
};
