import styles from "./Header.module.scss";
import pepexTitle from "src/modules/common/assets/img/svg/pepexTitle.svg";
import headerBackground from "src/modules/common/assets/img/svg/headerBackground.svg";
import play from "src/modules/common/assets/img/svg/play.svg";
import menu from "src/modules/landing/assets/img/svg/menu.svg";
import cross from "src/modules/landing/assets/img/svg/cross.svg";
import { useState } from "react";
import cl from "classnames";
import { HashLink } from "react-router-hash-link";

export const Header = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<div className={cl(styles.content, { [styles.contentMobile]: isOpen })}>
			<div className={styles.background}></div>
			<img src={headerBackground} alt="" className={styles.headerBackground} />
			<div className={styles.mainContent}>
				<img src={pepexTitle} alt="" className={styles.pepexTitle} />
				<HashLink smooth to="/#howToMint" className={styles.text}>
					How to mint
				</HashLink>
				<HashLink smooth to="/#aboutTheCollection" className={styles.text}>
					about
				</HashLink>
				<HashLink smooth to="/#exploreCharacters" className={styles.text}>
					characters
				</HashLink>
				<HashLink smooth to={"/#pvp"} className={styles.text}>
					Pvp mode
				</HashLink>
				<HashLink smooth to={"/#roadmap"} className={styles.text}>
					roadmap
				</HashLink>
				<HashLink smooth to={"/#tokenomics"} className={styles.text}>
					tokenomics
				</HashLink>
				<div className={styles.btn}>
					<img src={play} alt="" />
					<div>Video Tutorial</div>
				</div>
				<div className={styles.menu} onClick={() => setIsOpen(!isOpen)}>
					<img src={isOpen ? cross : menu} alt="" />
				</div>
			</div>
			{isOpen && (
				<div className={styles.menuMobile}>
					<div>How to mint</div>
					<div>about</div>
					<div>characters</div>
					<div>Pvp mode</div>
					<div>roadmap</div>
					<div>tokenomics</div>
					<div className={styles.btnMobile}>
						<img src={play} alt="" />
						<div>Video Tutorial</div>
					</div>
				</div>
			)}
		</div>
	);
};
