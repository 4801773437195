import styles from "./HowToMint.module.scss";
import cl from "classnames";
import wallet from "src/modules/landing/assets/img/svg/wallet.svg";
import cashback from "src/modules/landing/assets/img/svg/cashback.svg";
import link from "src/modules/landing/assets/img/svg/link.svg";
import mint from "src/modules/landing/assets/img/svg/mint.svg";
import line from "src/modules/landing/assets/img/svg/line.svg";
import ClaimButton from "../ClaimButton/ClaimButton";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";
import ScrollAnimation from "react-animate-on-scroll";

export const HowToMint = () => {
	return (
		<div className={styles.content} id={"howToMint"}>
			<div className={styles.wTutorial}>
				<div className={styles.tutorial}>
					<div>tutorial</div>
				</div>
			</div>
			<div className={styles.title}>how to mint</div>
			<div className={styles.cards}>
				<div className={cl(styles.card, styles.card1)}>
					<div className={styles.circle}>
						<img src={wallet} alt="" />
					</div>
					<div>
						Connect your
						<br /> wallet
					</div>
				</div>
				<div className={cl(styles.card, styles.card2)}>
					<div className={styles.circle}>
						<img src={link} alt="" />
					</div>
					<div>
						Enter the <br />
						Ref code or Free-
						<br />
						Mint code
					</div>
				</div>
				<div className={cl(styles.card, styles.card3)}>
					<div className={styles.circle}>
						<img src={mint} alt="" />
					</div>
					<div>Mint</div>
				</div>
				<div className={cl(styles.card, styles.card4)}>
					<div className={styles.circle}>
						<img src={cashback} alt="" />
					</div>
					<div>
						Receive your
						<br /> referral cashback and nft
					</div>
				</div>
			</div>
			<div className={styles.steps}>
				<div className={styles.step}>
					<div className={styles.stepCircle}></div>
					<div>step 1</div>
				</div>
				<img src={line} alt="" />
				<div className={styles.step}>
					<div className={styles.stepCircle}></div>
					<div>step 2</div>
				</div>
				<img src={line} alt="" className={styles.line} />
				<div className={styles.step}>
					<div className={styles.stepCircle}></div>
					<div>step 3</div>
				</div>
				<img src={line} alt="" />
				<div className={styles.step}>
					<div className={styles.stepCircle}></div>
					<div>step 4</div>
				</div>
			</div>
			<ScrollAnimation animateIn={styles.animateBtn} initiallyVisible={true}>
				<div className={styles.btns}>
					<ClaimButton>claim free-mint</ClaimButton>
					<div className={styles.arrowRight}>
						<img src={arrowRight} alt="" />
					</div>
				</div>
			</ScrollAnimation>
		</div>
	);
};
