import styles from "./Roadmap.module.scss";
import roadmap from "src/modules/landing/assets/img/svg/roadmap.svg";
import upload from "src/modules/landing/assets/img/svg/upload.svg";
import pencil from "src/modules/landing/assets/img/svg/pencil.svg";
import earth from "src/modules/landing/assets/img/svg/earth.svg";
import bolt from "src/modules/landing/assets/img/svg/bolt.svg";
import lightnings from "src/modules/landing/assets/img/svg/lightnings.svg";
import shadowRoadmap from "src/modules/landing/assets/img/svg/shadowRoadmap.svg";
import shadowIcon from "src/modules/landing/assets/img/svg/shadowIcon.svg";
import shadowRoad from "src/modules/landing/assets/img/png/shadowRoad.png";

export const Roadmap = () => {
	return (
		<div className={styles.content} id={"roadmap"}>
			<div className={styles.title}>
				Roadmap to <span>pepex</span>
			</div>
			<div className={styles.roadmap}>
				<div className={styles.road}>
					<div className={styles.leftColumn}>
						<div>q3 2024</div>
						<div>
							<div>q1 2025</div>
							<img src={shadowRoad} alt="" className={styles.shadowRoad} />
						</div>
					</div>
					<img src={roadmap} alt="" />
					<div className={styles.rightColumn}>
						<div>q4 2024</div>
						<div>TBA</div>
					</div>
				</div>
				<div className={styles.launch}>
					<img src={shadowRoadmap} alt="" />
					<div className={styles.rows}>
						<div className={styles.row}>
							<div className={styles.left}>
								<img src={shadowIcon} alt="" />
								<img src={pencil} alt="" />
							</div>
							<div className={styles.text}>
								<div>PvP Online Game Launch</div>
								<div className={styles.access}>
									Early access for NFT holders + Mystery Boxes. Second part of
									NFT utility revealed.
								</div>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.left}>
								<img src={shadowIcon} alt="" />
								<img src={bolt} alt="" />
							</div>
							<div className={styles.text}>
								<div>SigmaVerse {"->"} PvP</div>
								<div>
									Onboarding campaign for SigmaVerse users into the PvP mode.
								</div>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.left}>
								<img src={shadowIcon} alt="" />
								<img src={lightnings} alt="" />
							</div>
							<div className={styles.text}>
								<div>NFT Second Coming</div>
								<div>Second collection + Launchpad announcement.</div>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.left}>
								<img src={shadowIcon} alt="" />
								<img src={upload} alt="" />
							</div>
							<div className={styles.text}>
								<div>Launchpad Demo-launch</div>
								<div>Second NFT collection sale through our launchpad.</div>
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.left}>
								<img src={shadowIcon} alt="" />
								<img src={earth} alt="" />
							</div>
							<div className={styles.text}>
								<div>Merchandise Launch + IRL Mass-Adoption Campaign</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
