import { Header } from "src/modules/common/ui/Header/Header";
import styles from "./MainPage.module.scss";
import { MainBanner } from "../MainBanner/MainBanner";
import { HowToMint } from "../HowToMint/HowToMint";
import { AboutTheCollection } from "../AboutTheCollection/AboutTheCollection";
import { CollectionUtility } from "../CollectionUtility/CollectionUtility";
import { ExploreCharacters } from "../ExploreCharacters/ExploreCharacters";
import { DiveInto } from "../DiveInto/DiveInto";
import { Showcase } from "../Showcase/Showcase";
import { Roadmap } from "../Roadmap/Roadmap";
import { FeesDistribution } from "../FeesDistribution/FeesDistribution";
import { Team } from "../Team/Team";
import { FAQS } from "../FAQS/FAQS";
import { JoinCommunity } from "../JoinCommunity/JoinCommunity";
import { Footer } from "src/modules/common/ui/Footer/Footer";

export const MainPage = () => {
	return (
		<div className={styles.content}>
			<Header />
			<MainBanner />
			<HowToMint />
			<AboutTheCollection />
			<CollectionUtility />
			<ExploreCharacters />
			<DiveInto />
			<Showcase />
			<Roadmap />
			<FeesDistribution />
			<Team />
			<FAQS />
			<JoinCommunity />
			<Footer />
		</div>
	);
};
