import { MainPage } from "./modules/landing/ui/MainPage/MainPage";
import { HashRouter } from "react-router-dom";

function App() {
	return (
		<HashRouter>
			<MainPage />
		</HashRouter>
	);
}

export default App;
