import styles from "./Showcase.module.scss";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";
import cl from "classnames";
import shop from "src/modules/landing/assets/img/png/shop.png";
import gameScreen from "src/modules/landing/assets/img/png/gameScreen.png";
import leaderboard from "src/modules/landing/assets/img/png/leaderboard.png";
import question from "src/modules/landing/assets/img/png/question.png";
import fightClub from "src/modules/landing/assets/img/png/fightClub.png";
import shadowQuestion from "src/modules/landing/assets/img/png/shadowQuestion.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

export const Showcase = () => {
	const [swiper, setSwiper] = useState<any>();
	const [realIndex, setRealIndex] = useState<number>(0);
	const windowSize = useWindowSize();

	const nextTo = () => {
		console.log("next");
		swiper !== undefined && swiper.slideNext();
	};

	const prevTo = () => {
		console.log("prev");
		swiper !== undefined && swiper.slidePrev();
	};

	return (
		<div className={styles.content}>
			<div className={styles.top}>
				<div className={styles.title}>
					Sigmaverse
					<br /> <span>showcase</span>
				</div>
				{Number(windowSize.width) > 800 && (
					<div className={styles.arrows}>
						<div
							className={cl(styles.arrow, styles.left)}
							onClick={() => prevTo()}
						>
							<img src={arrowRight} alt="" />
						</div>
						<div className={styles.arrow} onClick={() => nextTo()}>
							<img src={arrowRight} alt="" />
						</div>
					</div>
				)}
			</div>
			<div>
				<Swiper
					className={cl(
						styles.images
						// { [styles.first]: realIndex === 1 },
						// { [styles.second]: realIndex === 2 },
						// { [styles.third]: realIndex === 0 }
					)}
					slidesPerView={3}
					loop={true}
					mousewheel={true}
					onSwiper={(s: any) => {
						setSwiper(s);
					}}
					onActiveIndexChange={(el) => {
						console.log("real index", el.realIndex);
						setRealIndex(el.realIndex);
					}}
					spaceBetween={Number(windowSize.width) > 530 ? 40 : 25}
				>
					<SwiperSlide>
						<img
							src={shop}
							alt=""
							style={{ opacity: realIndex === 2 ? 1 : 0.5 }}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={gameScreen}
							alt=""
							style={{ opacity: realIndex === 0 ? 1 : 0.5 }}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src={leaderboard}
							alt=""
							style={{ opacity: realIndex === 1 ? 1 : 0.5 }}
						/>
					</SwiperSlide>
				</Swiper>
			</div>
			{Number(windowSize.width) <= 800 && (
				<div className={styles.circles}>
					<div
						className={cl({ [styles.circleDisabled]: realIndex !== 0 })}
					></div>
					<div
						className={cl({ [styles.circleDisabled]: realIndex !== 1 })}
					></div>
					<div
						className={cl({ [styles.circleDisabled]: realIndex !== 2 })}
					></div>
				</div>
			)}
			<div className={styles.question} id={"pvp"}>
				<img src={question} alt="" />
				<img src={fightClub} alt="" />
			</div>
			<img src={shadowQuestion} alt="" className={styles.shadow} />
		</div>
	);
};
