import React, { ButtonHTMLAttributes } from "react";
import styles from "./ClaimButton.module.scss";
import cl from "classnames";

interface IClaimButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: any;
	className?: string;
}

const ClaimButton: React.FC<IClaimButton> = ({
	children,
	className,
	...props
}) => {
	return (
		<button className={cl(styles.btn, className)} {...props}>
			{children}
		</button>
	);
};

export default ClaimButton;
