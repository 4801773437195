import ScrollAnimation from "react-animate-on-scroll";
import styles from "./Team.module.scss";
import betman from "src/modules/landing/assets/img/png/betman.png";
import ClaimButton from "../ClaimButton/ClaimButton";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";

export const Team = () => {
	return (
		<div className={styles.content}>
			<div className={styles.gradientRed}></div>
			<div className={styles.title}>
				<div>team</div>
			</div>
			<div className={styles.subTitle}></div>
			<div className={styles.founders}>
				<div className={styles.founder}>
					<div className={styles.left}>
						<img src={betman} alt="" className={styles.betman} />
					</div>
					<div className={styles.right}>
						<div>Egor</div>
						<div>Founder, CEO.</div>
					</div>
				</div>
				<div className={styles.founder}>
					<div className={styles.left}>
						<img src={betman} alt="" className={styles.betman} />
					</div>
					<div className={styles.right}>
						<div>Kapcrew</div>
						<div>
							Professional dev team of 14 people with a 6+ year S-tier
							track-record.
						</div>
					</div>
				</div>
				<div className={styles.founder}>
					<div className={styles.left}>
						<img src={betman} alt="" className={styles.betman} />
					</div>
					<div className={styles.right}>
						<div>PEPEX Team</div>
						<div>CM, SMM and Marketing specialists.</div>
					</div>
				</div>
				<div className={styles.founder}>
					<div className={styles.left}>
						<img src={betman} alt="" className={styles.betman} />
					</div>
					<div className={styles.right}>
						<div>Strong Community</div>
						<div>
							Every community member is a part of our team. We compound our
							skills, knowledge and effort to build the best products in the
							market.
						</div>
					</div>
				</div>
			</div>
			<div className={styles.ecosystem}>
				<div className={styles.ecosystemContent}>
					<div>
						<span>Visit the</span> ecosystem's website
						<br /> to learn <span>more</span>
					</div>
					<ScrollAnimation
						animateIn={styles.animateBtn}
						initiallyVisible={true}
					>
						<div className={styles.btns}>
							<ClaimButton className={styles.btn}>
								visit the website
							</ClaimButton>
							<div className={styles.arrowRight}>
								<img src={arrowRight} alt="" />
							</div>
						</div>
					</ScrollAnimation>
				</div>
			</div>
		</div>
	);
};
