import ClaimButton from "../ClaimButton/ClaimButton";
import styles from "./CollectionUtility.module.scss";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";
import cl from "classnames";
import trailer from "src/modules/landing/assets/img/svg/trailer.svg";
import fightLeft from "src/modules/landing/assets/img/png/fightLeft.png";
import fightRight from "src/modules/landing/assets/img/png/fightRight.png";
import loot from "src/modules/landing/assets/img/png/loot.png";
import perks from "src/modules/landing/assets/img/png/perks.png";
import ScrollAnimation from "react-animate-on-scroll";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from "@uidotdev/usehooks";
import "swiper/css";

export const CollectionUtility = () => {
	const [swiper, setSwiper] = useState<any>();
	const [realIndex, setRealIndex] = useState<number>(0);
	const windowSize = useWindowSize();

	const nextTo = () => {
		console.log("next");
		swiper !== undefined && swiper.slideNext();
	};

	const prevTo = () => {
		console.log("prev");
		swiper !== undefined && swiper.slidePrev();
	};

	return (
		<div className={styles.wContent}>
			<div className={styles.content}>
				<div className={styles.titleBlock}>
					<div className={styles.title}>collection UTILITY</div>
					<div className={styles.titleRight}>
						<div>
							WHY YOU <span>NEED</span> A PEPE?
						</div>
						<div>
							{Number(windowSize.width) > 500 ? (
								<div>
									Get acquainted with all the perks. We’ve
									<br /> got a lot to show!
								</div>
							) : (
								<div>
									Get acquainted with all the perks.
									<br /> We’ve got a lot to show!
								</div>
							)}
						</div>
						<ScrollAnimation
							animateIn={styles.animateBtn}
							initiallyVisible={true}
						>
							<div className={styles.btns}>
								<div className={styles.btn}>
									<ClaimButton className={styles.black}>
										claim free-mint
									</ClaimButton>
									<div
										className={cl(
											styles.arrowRight,
											styles.black,
											styles.animateBlack
										)}
									>
										<img src={arrowRight} alt="" />
									</div>
								</div>
								<div className={styles.btn}>
									<ClaimButton>watch trailer</ClaimButton>
									<div className={cl(styles.arrowRight, styles.animate)}>
										<img src={trailer} alt="" />
									</div>
								</div>
							</div>
						</ScrollAnimation>
					</div>
				</div>
				<div className={styles.wCards}>
					{" "}
					<div
						className={cl(styles.arrowRight, styles.left)}
						onClick={() => prevTo()}
					>
						<img src={arrowRight} alt="" />
					</div>
					<div
						className={cl(styles.arrowRight, styles.right)}
						onClick={() => nextTo()}
					>
						<img src={arrowRight} alt="" />
					</div>
					<Swiper
						className={styles.cards}
						slidesPerView={
							Number(windowSize.width) > 1416
								? 4
								: Number(windowSize.width) > 1060
								? 3
								: Number(windowSize.width) > 720
								? 2
								: 1
						}
						loop={true}
						onSwiper={(s: any) => {
							setSwiper(s);
						}}
						onActiveIndexChange={(el: { realIndex: any }) => {
							console.log("real index", el.realIndex);
							//setRealIndex(el.realIndex);
						}}
						spaceBetween={28}
					>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard1}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card1)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>25% SigmaVerse Revenue Sharing</div>
											<img
												src={fightLeft}
												alt=""
												className={styles.fightLeft}
											/>
											<img
												src={fightRight}
												alt=""
												className={styles.fightRight}
											/>
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>
												25% of total game revenue will be distributed among 1000
												NFT holders.
											</li>
											<li>
												For every NFT, get 0.025% of SigmaVerse revenue (250$
												for every $1M revenue)
											</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard2}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card2)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>Exclusive In-game Perks</div>
											<img src={loot} alt="" className={styles.loot} />
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>Exclusive Weekly Lootbox With $$$ Prizes.</li>
											<li>Auto-tap and more.</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard3}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card3)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>SigmaVerse NFT DAO</div>
											<div className={styles.launch}>Launches in Q4, 2024</div>
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>Become a co-owner of SigmaVerse game.</li>
											<li>Governance votes.</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
						<SwiperSlide>
							<ScrollAnimation
								animateIn={styles.animateCard4}
								initiallyVisible={true}
							>
								<div className={cl(styles.card, styles.card4)}>
									<div className={styles.wCardTop}>
										<div className={styles.cardTop}>
											<div>Exclusive Access + OG Perks</div>
											<img src={perks} alt="" className={styles.perks} />
										</div>
									</div>
									<div className={styles.border}></div>
									<div className={styles.wList}>
										<ul className={styles.list}>
											<li>Access to a private trading community - #1MCLUB.</li>
											<li>Access to future IRL events.</li>
											<li>
												Whitelist spots for possible future launches / events.
											</li>
										</ul>
									</div>
								</div>
							</ScrollAnimation>
						</SwiperSlide>
					</Swiper>
				</div>
				<div className={styles.collect}>
					<div>
						Collect all 10 unique characters and get a quest board boost
					</div>
				</div>
				<div className={styles.exclusive}>EXCLUSIVE</div>
			</div>
		</div>
	);
};
