import styles from "./ExploreCharacters.module.scss";
import cl from "classnames";
import shadowYellow from "src/modules/landing/assets/img/svg/shadowYellow.svg";
import shadowYellowMobile from "src/modules/landing/assets/img/svg/shadowYellowMobile.svg";
import officer from "src/modules/landing/assets/img/png/officer.png";
import shadowPurple from "src/modules/landing/assets/img/svg/shadowPurple.svg";
import shadowPurpleMobile from "src/modules/landing/assets/img/svg/shadowPurpleMobile.svg";
import shadowBlue from "src/modules/landing/assets/img/svg/shadowBlue.svg";
import shadowBlueMobile from "src/modules/landing/assets/img/svg/shadowBlueMobile.svg";
import ClaimButton from "../ClaimButton/ClaimButton";
import arrowRight from "src/modules/landing/assets/img/svg/arrowRight.svg";
import arrowRightBlack from "src/modules/landing/assets/img/svg/arrowRightBlack.svg";
import ScrollAnimation from "react-animate-on-scroll";
import shadowExplore from "src/modules/landing/assets/img/svg/shadowExplore.svg";
import { useWindowSize } from "@uidotdev/usehooks";

export const ExploreCharacters = () => {
	const windowSize = useWindowSize();

	return (
		<div className={styles.content} id="exploreCharacters">
			<img src={shadowExplore} alt="" className={styles.shadowExplore} />
			<div className={styles.title}>explore unique characters</div>
			<div className={styles.sectionCharacters}>
				{Number(windowSize.width) <= 1430 && (
					<div className={cl(styles.arrowRightBlack, styles.arrowLeft)}>
						<img src={arrowRightBlack} alt="" />
					</div>
				)}
				{Number(windowSize.width) <= 1430 && (
					<div className={styles.arrowRightBlack}>
						<img src={arrowRightBlack} alt="" />
					</div>
				)}
				<div className={styles.wCharacters}>
					{Number(windowSize.width) > 1430 && (
						<div className={cl(styles.arrowRightBlack, styles.arrowLeft)}>
							<img src={arrowRightBlack} alt="" />
						</div>
					)}
					<div className={styles.characters}>
						<div className={cl(styles.character, styles.characterYellow)}>
							<div className={styles.characterTop}>
								<img
									src={
										Number(windowSize.width) > 620
											? shadowYellow
											: shadowYellowMobile
									}
									alt=""
									className={styles.shadow}
								/>
								<img src={officer} alt="" className={styles.officer} />
								{Number(windowSize.width) > 630 && (
									<div className={styles.text}>officer p</div>
								)}
							</div>
							<div className={styles.characterBottom}>legendary</div>
						</div>
						<div className={cl(styles.character, styles.characterPurple)}>
							<div className={styles.characterTop}>
								<img
									src={
										Number(windowSize.width) > 620
											? shadowPurple
											: shadowPurpleMobile
									}
									alt=""
									className={styles.shadow}
								/>
								<img src={officer} alt="" className={styles.officer} />
								<div className={styles.text}>officer p</div>
							</div>
							<div className={styles.characterBottom}>epic</div>
						</div>
						<div className={cl(styles.character, styles.characterBlue)}>
							<div className={styles.characterTop}>
								<img
									src={
										Number(windowSize.width) > 620
											? shadowBlue
											: shadowBlueMobile
									}
									alt=""
									className={styles.shadow}
								/>
								<img src={officer} alt="" className={styles.officer} />
								<div className={styles.text}>officer p</div>
							</div>
							<div className={styles.characterBottom}>rare</div>
						</div>
					</div>
					{Number(windowSize.width) > 1430 && (
						<div className={styles.arrowRightBlack}>
							<img src={arrowRightBlack} alt="" />
						</div>
					)}
				</div>
			</div>
			<ScrollAnimation animateIn={styles.animateBtn} initiallyVisible={true}>
				<div className={styles.btns}>
					<ClaimButton>claim free-mint</ClaimButton>
					<div className={styles.arrowRight}>
						<img src={arrowRight} alt="" />
					</div>
				</div>
			</ScrollAnimation>
		</div>
	);
};
